<template>
  <div >
    <v-layout wrap class="mainbgx" style="height: 100vh;">
      <v-flex>
        <v-layout wrap justify-center >
      <v-flex xs12 align-self-center>
        <v-snackbar
          v-model="showSnackBar"
          color="black"
          right
          :timeout="timeout"
        >
          <v-layout wrap justify-center>
            <v-flex text-left class="align-self-center">
              <span style="color: white">
                {{ msg }}
              </span>
            </v-flex>
            <v-flex text-right>
              <v-btn small :ripple="false" text @click="showSnackBar = false">
                <v-icon style="color: white">mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-snackbar>
      </v-flex>
    </v-layout>

  <v-layout wrap fill-height>
    <v-flex align-self-center>

     

    
    <v-layout wrap justify-center>
      <v-flex xs4>
        <v-card class="pa-4">

          <v-layout wrap justify-center >
      <v-flex xs4>
        <v-layout wrap justify-center>
          <v-flex xs6 text-center pt-2 class="mainfont">
            <v-img
              src="../../assets/Images/Lockimg.png"
              width="100%"
              height="100%"
            ></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
          <v-layout wrap justify-center>
          <v-flex xs12 text-center pt-2 class="mainfont">
            <span style="font-size: 22px;">Trouble logging in?</span>
          </v-flex>
        </v-layout>

        <v-layout justify-center>
          <v-flex xs12 pt-6>
            <v-text-field
              :rules="emailIdRules"
              color="#717171"
              label="E-mail"
              outlined
              dense
              style="font-family: mainfont"
              v-model="emailId"
            >
            </v-text-field>
          </v-flex>
        </v-layout>

        <v-layout justify-center>
          <v-flex pt-0 xs12>
            <v-text-field
              color="#717171"
              label="Password"
              outlined
              dense
              style="font-family: mainfont"
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              hide-details
            >
            </v-text-field>
          </v-flex>
        </v-layout>

        <v-layout justify-center>
          <v-flex pt-6 xs12>
            <v-text-field
              color="#717171"
              label="Confirm Password"
              outlined
              dense
              style="font-family: mainfont"
              v-model="confirmpassword"
              :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword2 ? 'text' : 'password'"
              @click:append="showPassword2 = !showPassword2"
              hide-details
            >
            </v-text-field>

            <v-layout wrap>
              <v-flex xs12 pt-3 class="mainfont">
                <v-alert v-if="!passwordsMatch" type="error">
                  Passwords do not match
                </v-alert>
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex pt-3 xs12>
                <v-btn
                  class="no-uppercase mainfont"
                  dark
                  block
                  color="#314755"
                  @click="validateInput()"
                >
                  <span>
                    Submit &nbsp;
                  </span>
                  <v-icon color="white">
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            
            <!-- <v-layout wrap>
              <v-flex
                @click="$router.push('/')"
                style="cursor: pointer;"
                pb-8
                class="mainfont"
                text-center
                pt-3
                xs10
              >
                <span>Already a user? click here to Login</span> -->
            <!-- </v-flex>
            </v-layout> -->
            
          </v-flex>
        </v-layout>

        </v-card>
        
      
        
      </v-flex>
    </v-layout>

    </v-flex>
  </v-layout>
  

      </v-flex>
    </v-layout>
  
  </div>
</template>

<script>
import axios from "axios";

export default {
  // import axios from "axios";

  data() {
    return {
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      emailId: null,

      showPassword: false,
      showPassword2: false,
      passwordsMatch: true,

      password: null,
      confirmpassword: null,
    };
  },
  mounted() {
    this.phone = this.$route.query.phone;
  },

  watch: {
    confirmpassword(newConfirmPassword) {
      if (newConfirmPassword !== this.password) {
        this.passwordsMatch = false;
      } else if (newConfirmPassword === null || this.password === null) {
        this.passwordsMatch = true;
      } else {
        this.passwordsMatch = true;
      }
    },
  },
  computed: {
    emailIdRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
  },

  methods: {
    validateInput() {
      if (!this.password) {
        this.msg = "Please Provide password";
        this.showSnackBar = true;
        return;
      } else if (!this.confirmpassword) {
        this.msg = "Please Confirm password";
        this.showSnackBar = true;
        return;
      } else if (!this.emailId) {
        this.msg = "Please Provide email";
        this.showSnackBar = true;
        return;
      } else {
        this.passwordSubmit();
      }
    },
    passwordSubmit() {
  axios({
    method: "POST",
    url: "/forgot/password",
    data: {
      emailId: this.emailId,
      password: this.password,
    },
  })
    .then((response) => {
      if (response.data.status) {
        this.msg = response.data.msg;
        this.showSnackBar = true;
        this.$router.push({ path: "/" });
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((error) => {
      // Handle error if needed
      console.error(error);
    });
}

    // login() {
    //     axios({
    //         method: "post",
    //         url: "/login/",
    //         data: {
    //             username: this.username,
    //             password: this.password,
    //         },
    //     })
    //         .then((response) => {
    //             if (response.data.status) {
    //                 store.commit("loginUser", response.data.token);
    //                 var utype = response.data.utype;
    //                 var path = response.data.path;
    //                 localStorage.setItem("utype", utype);
    //                 localStorage.setItem("path", path);
    //                 if (utype === 777) {
    //                     console.log("hghbg")
    //                     this.$router.push({ path: "/DashboardPage" });
    //                 } else {
    //                     this.$router.push({ path: "/divisiondashboard" });
    //                 }
    //             } else {
    //                 this.msg = response.data.msg;
    //                 this.showSnackBar = true;
    //             }
    //         })
    //         .catch((err) => {
    //             store.commit("appLoading", false);
    //             this.ServerError = true;
    //             console.log(err);
    //         });
    // },
  },
};
</script>
<style>
.mainbgx {
  background-image: linear-gradient(269.6deg, #1cb5e0 -31.66%, #000046);
}

/* Apply a gradient background color to an element with class 'bgcustom' */
.bgcustom {
  background: linear-gradient(180deg, #314755 0%, #26a0da 100%);
  color: white;
  /* Set the text color */
}
</style>
